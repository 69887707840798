import logo from './logo.png';
import title from './ES_title.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className='no-margin'>
        <img src={title} className="App-title" alt="title" />
        </p>
        <p
          className="App-link"
        >
          Coming soon
        </p>
      </header>
    </div>
  );
}

export default App;
